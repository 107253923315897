<template>
    <section class="tags py-4">
        <div class="tags-matrix overflow-auto hidden-scroll position-relative">
            <div class="father d-middle mb-2">
                <div class="child wf-295px hf-24px" style="background-color:#F6F9FB !important" />
                <div v-for="(item, index) in descuentos" :key="index" class="d-middle-center wf-200px text-black2">
                    <p>{{ item.nombre }}</p>
                </div>
            </div>
            <div class="">
                <div v-for="(tag, index) in etiquetas" :key="index" class="father d-middle">
                    <div class="child d-middle px-4 wf-295px bg-white h-55px border-right border-bottom">
                        <p>{{ tag.texto }}</p>
                    </div>
                    <div v-for="(d, idx) in tag.descuentos" :key="idx" class="d-middle-center wf-200px bg-white h-55px text-black2 border-bottom">
                        <!-- <span v-if="d.valor != null" class="wf-78px hf-32px">{{ d.valor }}</span> -->
                        <el-input v-model="d.valor" placeholder="%" size="medium" class="wf-78px hf-32px" @change="setDescuento(d.valor, tag.id, d.id)" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Service from '~/services/configurar/admin/descuentosLista';
export default {
    data(){
        return {
            etiquetas:[],
            descuentos:[],
        }
    },
    mounted(){
        this.getMatriz();
    },
    methods: {
        async getMatriz(){
            try {
                const {data} = await Service.getMatriz();
                this.etiquetas = data.etiquetas;
                this.descuentos = data.descuentos;
            } catch(e){
                this.error_catch(e);
            }
        },
        async setDescuento(valor, idTag, idDesc){

            let value = parseFloat(valor)

            if(isNaN(value) || value == undefined || value == null || value == ''){
                value = 0
            }

            try {
                const payload = {
                    valor:value,
                    idEtiqueta:idTag,
                    idDesc:idDesc
                }

                const {data} = await Service.uploadMatriz(payload);
                this.notificacion('Exito','Registro actualizado correctamente','success')
                this.getMatriz();

            } catch (e){
                this.error_catch(e);
            }
        },
        handleEditTag(){
        },
    }
}
</script>
<style lang="scss" scoped>
.wM-521px{
	max-width: 521px;
}
.tags-matrix{
	position: relative;
	.father{
		.child{
			background: white !important;
			position: sticky;
			left: 0px;
			z-index: 999;
		}
	}
}
</style>
